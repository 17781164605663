.tooltip-normal {
    max-width: 800px!important;
}

.tooltip-normal .ant-tooltip-inner {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0, 0.65);
}

.tooltip-success .ant-tooltip-arrow::before,
.tooltip-error .ant-tooltip-arrow::before,
.tooltip-normal .ant-tooltip-arrow::before {
    background-color: rgb(255, 255, 255);
}

.tooltip-error .ant-tooltip-inner {
    background-color: rgb(255, 255, 255);
    color: #ff4d4f;
}

.tooltip-success .ant-tooltip-inner {
    background-color: rgb(255, 255, 255);
    color: #73d13d;
}

.drawer-header .timer {
    color: #fff;
    user-select: none;
}

.drawer-header .ant-drawer-body,
.drawer-footer .ant-drawer-body {
    padding: 0 1rem;
}

.drawer-header .content,
.drawer-footer .content {
    height: 64px;
}

.drawer-header .ant-drawer-content {
    background-color:transparent;
    background-image: linear-gradient(to bottom,rgba(0,0,0,.6),rgba(0,0,0,0))
}
.drawer-footer .ant-drawer-content {
    background-color:transparent;
    background-image: linear-gradient(to top,rgba(0,0,0,.6),rgba(0,0,0,0))
}

.drawer-header .icons,
.drawer-footer .icons {
    text-align: center;
}

.drawer-header .icons .theme {
    margin: 0 auto;
    font-size: 22px;
    color: #fff;
    display: table;
    /* user-select: none;  */
}

.drawer-header .icons .icon,
.drawer-footer .icons .icon {
    border-radius: 4px;
    font-size: 24px;
    transition: all 0.25s;
    text-align: right;
    padding: 8px;
    margin-right: 4px;
}

.drawer-header .icons .right,
.drawer-footer .icons .right {
    float: right;
}

.drawer-footer .icons .left {
    float: left;
}

.drawer-header .icons .icon:hover,
.drawer-footer .icons .icon:hover {
    cursor: pointer;
    background-color: #ffffff7F;
    -webkit-transition:background-color .5s ease-in-out;
    -moz-transition:background-color .5s ease-in-out;
    -o-transition:background-color .5s ease-in-out;
    -ms-transition:background-color .5s ease-in-out;
    transition:background-color .5s ease-in-out;
}

.drawer-header .icons .selected,
.drawer-footer .icons .selected {
    background-color: #ffffff7F;
}
