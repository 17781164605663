

.debug {
    position: absolute;
    left: 2rem;
    top: 2rem;
    z-index: 2000;
    /* background: #91d5ff; */
    background: #FFF;
    width: 50%;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.debug .ant-descriptions-title {
    margin: 1rem 1rem;
}

.not-find-wapper  {
    display: flex;
    align-items: center;
}
.not-find {
    margin: 0 auto;
}

.main {
    position: relative;
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;
}

.main .icons {
    position: absolute;
    opacity: 0;
    font-size: 22px;
    top: 4px;
    right: 4px;
}

.main .icons .icon {
    border-radius: 50%;
    margin-left: 0.5rem;
}

.main .icons .icon:hover {
    cursor: pointer;
    transform: scale(1.1, 1.1);
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.3);
}

.main .live-overlap,
.main .board-overlap {
    position: relative;
}

.main .live-overlap .live,
.main .board-overlap .board {
    z-index: -1;
}

.main .live-overlap .board,
.main .board-overlap .live {
    position: absolute;

    top: 4rem;
    right: 1rem;

    width: 320px;
    height: 240px;
    z-index: 100;

    background: #000;

    border-radius: 4px;
    border: 1px solid transparent;
    overflow: hidden; 
}

.main .live-overlap .board:hover,
.main .board-overlap .live:hover {
    border: 1px solid #1717177F;
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.3);
}

.main .live-overlap .board:hover .icons,
.main .board-overlap .live:hover .icons  {
    opacity: 1;
    z-index: 1002;

    -webkit-transition:opacity .5s ease-in-out;
    -moz-transition:opacity .5s ease-in-out;
    -o-transition:opacity .5s ease-in-out;
    -ms-transition:opacity .5s ease-in-out;
    transition:opacity .5s ease-in-out;
}

